<template>
  <box
    :class="{
      'box--widget': true,
      'box--moveable': customizing,
      [`is-color-${widgetColor}`]: widgetColor,
      'box--bare':
        (item && item.viz === 'map') || (item && item.viz === 'bpmap')
    }"
  >
    <div slot="header">
      <div v-if="item" class="columns is-vcentered">
        <div class="column">
          <input
            v-if="customizing"
            v-model="item.title"
            type="text"
            class="input--simple"
          >

          <p v-else class="has-text-weight-bold">
            {{ item.title }}
          </p>
        </div>

        <div class="column is-narrow">
          <range-picker
            ref="rangepicker"
            :disabled="!customizing || timeLock"
            small
            :range.sync="item.dates"
            :lookup.sync="item.range"
          />
        </div>
      </div>

      <p v-else>
        N/A
      </p>
    </div>

    <visualization
      v-if="item"
      ref="viz"
      :data="vizData"
      :loading="loading"
      @page="tablePageUpdated"
    >
      <template v-slot:below-chart="props">
        <div v-if="customizing" class="columns is-gapless is-vcentered">
          <div class="column">
            <div class="field is-grouped">
              <div class="control is-expanded">
                <input
                  v-if="customizing"
                  v-model="item.query"
                  type="text"
                  class="input input--simple is-small"
                >
              </div>

              <div v-if="!props || !props.sum" class="control">
                <div class="select is-small">
                  <select v-model="item.viz" required>
                    <option disabled value="">
                      {{ $t('chart.type') }}
                    </option>

                    <option
                      v-for="v in vizs"
                      :key="v.value"
                      :value="v.value"
                      :disabled="v.disabled"
                    >
                      {{ $l(`chart.${v.value}`, v.name) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!props || !props.sum" class="column is-narrow">
            <button
              class="button is-text is-small is-uppercase"
              @click.prevent="
                $router.push({ name: 'search', params: { widget: item } })
              "
            >
              <octicon :icon="searchIcon" />

              <span>{{ $t('details') }}</span>
            </button>
          </div>

          <div class="column is-narrow">
            <button
              class="button is-text is-small is-uppercase"
              @click.prevent="$emit('delete')"
            >
              <octicon :icon="x" />

              <span>{{ $t('remove') }}</span>
            </button>
          </div>

          <div class="column is-narrow">
            <button
              class="button is-text is-small is-uppercase"
              @click.prevent="reverse"
            >
              <octicon :icon="sync" />

              <span>{{ $t('reverse') }}</span>
            </button>
          </div>
        </div>
      </template>
    </visualization>
  </box>
</template>

<script>
import { note, search, x, sync } from 'octicons-vue'
import { charts, debounce } from '@/utils'
import RangePicker from '@/components/RangePicker'
import Visualization from '@/components/Visualization'

export default {
  components: { RangePicker, Visualization },
  props: {
    id: {
      type: String,
      required: true
    },
    widgets: {
      type: Array,
      required: true
    },
    customizing: {
      type: Boolean,
      required: true
    },
    timeLock: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      result: null,
      loading: true,
      rnr: null,
      from: 0
    }
  },
  computed: {
    note () {
      return note
    },
    searchIcon () {
      return search
    },
    x () {
      return x
    },
    sync () {
      return sync
    },
    widgetColor () {
      if (!this.item || !this.item.color) {
        return ''
      }

      return this.item.color
    },
    vizs () {
      if (!this.result || !(this.result.types instanceof Array)) {
        return []
      }

      return charts().map(viz => {
        if (this.result.types.indexOf(viz.value) < 0) {
          viz.disabled = true
        }

        return viz
      })
    },
    dates () {
      if (!this.item || !this.item.dates) {
        return []
      }

      return this.item.dates.map(x => new Date(x).getTime())
    },
    resultEvents () {
      if (!this.result) {
        return []
      }

      return this.result.events instanceof Array ? this.result.events : []
    },
    resultRange () {
      if (this.item && !this.item.range) {
        return ''
      }

      return this.item.range
    },
    resultTotal () {
      if (!this.result) {
        return []
      }

      return this.result.total instanceof Array ? this.result.events : 0
    },
    resultGeo () {
      return this.result.geo instanceof Array ? this.result.geo : []
    },
    vizData () {
      if (!this.item) {
        return {}
      }

      if (!this.result) {
        return {
          type: this.item.viz,
          events: []
        }
      }

      if (this.item && this.item.viz === 'bpmap') {
        return {
          type: this.item.viz,
          data: {
            chart_type: 'count_statistic',
            type: this.item.viz,
            data: this.result.events ? this.result.events : {}
          }
        }
      }

      if (this.dates.length !== 2) {
        return {
          type: this.item.viz,
          events: []
        }
      }

      if (this.item.viz === 'table') {
        if (this.result.chartType === 'count_statistic') {
          return {
            chartType: this.result.chartType,
            type: this.item.viz,
            total: this.resultEvents.length,
            events: this.resultEvents,
            columns: this.result.fieldsName,
            range: this.resultRange,
            query: this.item.query,
            start: this.dates[0],
            end: this.dates[1]
          }
        }

        return {
          chartType: this.result.chartType,
          type: this.item.viz,
          total: this.resultTotal,
          events: this.resultEvents,
          columns: this.result.fields,
          range: this.resultRange,
          query: this.item.query,
          start: this.dates[0],
          end: this.dates[1]
        }
      }

      if (this.item.viz === 'sum') {
        return {
          type: this.item.viz,
          query: this.item.query,
          range: this.resultRange,
          start: this.dates[0],
          end: this.dates[1],
          total: this.result.total
        }
      }

      if (this.item.viz === 'map') {
        return {
          type: this.item.viz,
          data: this.resultGeo,
          range: this.resultRange,
          query: this.item.query,
          originalData: this.result.data,
          currentLocation: this.result.currentLocation,
          field: this.result.geoField,
          rootField: this.result.geoRoot,
          direction_reverse: this.item.direction_reverse
        }
      }

      if (this.item.viz === 'bpmap') {
        return {
          type: this.item.viz,
          data: this.result.data
        }
      }

      return {
        type: this.item.viz,
        events: this.resultEvents,
        query: this.item.query,
        range: this.resultRange,
        interval: this.result.interval,
        start: this.dates[0],
        end: this.dates[1]
      }
    },
    item () {
      if (!this.widgets) {
        return null
      }

      const item = this.widgets.find(w => w.id === this.id)
      return item
    }
  },
  watch: {
    item: {
      deep: true,
      handler () {
        // we should ignore dispatches for
        // item.title & item.viz but hey
        // we are cheating when mutating a computed prop
        // so the newVal and oldVal will always be the same
        // it's kinda impossible to determine dirty changes

        this.$nextTick(() => {
          this.dispatchRnR()
        })
      }
    },
    customizing: 'resize'
  },
  created () {
    this.rnr = debounce(this.registerChangeAndReload, 300)
  },
  mounted () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type !== 'ui/UI_UPDATE_SIDEBAR') {
        return
      }

      setTimeout(() => {
        this.resize()
      }, 300) // ui transition duration
    })
  },
  methods: {
    tablePageUpdated (page) {
      this.from = 10 * (page - 1)
    },
    refresh () {
      if (this.customizing) {
        return
      }

      if (this.item && !this.item.range) {
        // absolute range
        this.search()
        return
      }

      if (!this.$refs.rangepicker) {
        return
      }

      this.$refs.rangepicker.reloadSuggestions()
    },
    resize () {
      if (!this.$refs.viz) {
        return
      }

      this.$refs.viz.resize()
    },
    dispatchRnR () {
      if (!(this.rnr instanceof Function)) {
        return
      }

      this.rnr()
    },
    registerChange () {
      if (!this.customizing) {
        return
      }

      this.$emit('change', this.item)
    },
    registerChangeAndReload () {
      this.registerChange()
      this.search()
    },
    search () {
      if (
        !this.item ||
        !this.item.query ||
        !this.dates ||
        this.dates.length !== 2
      ) {
        return
      }

      this.loading = true
      return this.$_search({
        query: this.item.query,
        start: this.dates[0],
        end: this.dates[1],
        from: this.from
      })
        .then(normalized => {
          this.result = normalized
        })
        .finally(() => {
          this.loading = false
        })
    },
    reverse () {
      if (!this.item) {
        return
      }

      this.item.direction_reverse = !this.item.direction_reverse
    }
  }
}
</script>

<style lang="scss">
.box--widget {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .box__header {
    flex-shrink: 0;
    font-weight: normal;
  }

  .box__body {
    flex: 1 1 auto;
    overflow-y: hidden;
  }

  &.box--bare {
    .box__body {
      padding: 0;
    }
  }

  .table-viz {
    height: 100%;
    overflow-y: hidden;
    font-size: 0.9em;

    [data-elm='wrapper'] {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    [data-elm='screen'] {
      flex: 1 1 auto;
      overflow-y: auto;
    }

    [data-elm='plink'] {
      font-size: 1em;
    }
  }
}

.box--moveable {
  border: 2px dashed #ccc;
}
</style>
