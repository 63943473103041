<template>
  <div>
    <grid-layout
      v-if="layout && layout.length"
      :layout.sync="layout"
      :col-num="12"
      :row-height="50"
      :is-draggable="customizing"
      :is-resizable="customizing"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="false"
      @layout-ready="layoutReadyEvent"
      @layout-updated="layoutUpdatedEvent"
    >
      <grid-item
        v-for="l in layout"
        :key="l.i"
        drag-ignore-from="a, button, input, path, react, select, option"
        :x="l.x"
        :y="l.y"
        :w="l.w"
        :h="l.h"
        :i="l.i"
      >
        <dashboard-chart
          v-if="ready"
          :id="l.i" ref="chart"
          :widgets="widgets" :customizing="customizing" :time-lock="timeLock"
          @delete="widgetRemovedEvent(l.i)" @change="widgetChangedEvent"
        />
      </grid-item>
    </grid-layout>
    <div v-else class="columns is-mobile is-centered">
      <div class="column is-half">
        <section class="hero has-text-centered">
          <div class="hero-body">
            <slot name="empty">
              <div slot="empty" class="container">
                <h1 class="title">
                  <octicon :icon="lightBulb" :scale="5" />
                </h1>
                <h2 class="subtitle is-uppercase">
                  {{ $t('errorNoWidgets') }}
                </h2>
                <p style="margin-top: 4em">
                  <button class="button is-primary" @click.prevent="widgetAddingEvent">
                    <octicon :icon="plus" /><span>{{ $t('newWidget') }}</span>
                  </button>
                </p>
              </div>
            </slot>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { lightBulb, plus } from 'octicons-vue'
import { GridLayout, GridItem } from 'vue-grid-layout'
import DashboardChart from '@/components/DashboardChart'
export default {
  name: 'WidgetWall',
  components: { GridLayout, GridItem, DashboardChart },
  props: {
    layout: {
      type: Array,
      default () {
        return null
      }
    },
    widgets: {
      type: Array,
      default () {
        return null
      }
    },
    customizing: {
      type: Boolean,
      default: false
    },
    timeLock: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      changed: {},
      ready: false
    }
  },
  computed: {
    lightBulb () {
      return lightBulb
    },
    plus () {
      return plus
    }
  },
  methods: {
    refresh () {
      if (!(this.$refs.chart instanceof Array)) {
        return
      }

      this.$refs.chart.forEach(chart => {
        if (typeof chart.refresh !== 'function') {
          return
        }

        chart.refresh()
      })
    },
    layoutReadyEvent () {
      window.setTimeout(
        () => {
          this.ready = true
        },
        200 // vue-grid-layout transition duration
      ) // make sure widgets don't glitch
    },
    layoutUpdatedEvent () {
      if (!(this.$refs.chart instanceof Array)) {
        return
      }

      this.$refs.chart.forEach(el => {
        el.resize()
      })
    },
    widgetChangedEvent (item) {
      if (!item.id) {
        return
      }

      this.changed[item.id] = item
      this.$emit('change:widgets', this.changed)
    },
    widgetRemovedEvent (id) {
      this.$emit('remove:widget', id)
    },
    widgetAddingEvent () {
      if (this.customizing) {
        this.$store.dispatch('NOTIFY', {
          type: 'warning',
          error: {
            message: this.$t('errorWidgetsCustomizing')
          }
        })
        return
      }

      this.$router.push({ name: 'search' })
    }
  }
}
</script>
